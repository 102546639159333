import { IMAGES } from "../constent/theme"
import { Link } from "react-router-dom"
import emailjs from "@emailjs/browser"
import toast, { Toaster } from "react-hot-toast"
import { useRef, useState } from "react"

const Footer2 = () => {
  const heartRef = useRef(null)
  const [input, setInput] = useState("")
  const form = useRef(null)
  const sendEmail = e => {
    e.preventDefault()
    setInput("")
    if (form.current) {
      emailjs
        .sendForm(
          "emailId",
          "template_0byuv32",
          form.current,
          "qUDIPykc776NYHv4m"
        )
        .then(
          () => {
            toast.success("Successfully send!")
          },
          error => {
            toast.error(error.text)
          }
        )
    }
  }
  return (
    <>
      <Toaster position="bottom-right" reverseOrder={true} />
      <footer className="site-footer style-2 mt-5" id="footer">
        <div className="footer-bg-wrapper" id="app-banner">
          <div className="footer-top">
            <div className="container">
            
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 wow fadeInUp">
                  <div className="widget widget_getintuch">
                    <h5 className="footer-title text-black">Contact</h5>
                    <ul>
                      <li>
                        <i className="flaticon-placeholder"></i>
                        <a className="text-black" href="https://www.google.com/maps/place/Ooka+Sushi+%26+Asian+Cuisine/@39.9147027,-105.0685075,3a,75y,235.61h,93.39t/data=!3m7!1e1!3m5!1sacgaATlMN7yeDE0zs2sGXQ!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DacgaATlMN7yeDE0zs2sGXQ%26cb_client%3Dsearch.gws-prod.gps%26w%3D86%26h%3D86%26yaw%3D275.90723%26pitch%3D0%26thumbfov%3D100!7i16384!8i8192!4m16!1m9!3m8!1s0x876b8a4f4100f4a9:0x150f56be30607c3e!2sParking+lot,+6485+W+120th+Ave,+Broomfield,+CO+80020!3b1!8m2!3d39.9147198!4d-105.0687108!10e5!16s%2Fg%2F11bw3xvjcg!3m5!1s0x876b8a4f479ecad1:0xc291b859f0111ce3!8m2!3d39.914713!4d-105.0686875!16s%2Fg%2F1hbpws237?coh=205409&entry=ttu" target="">
                        6485 W.120th Ave, Broomfield,CO 80020
                        </a>
                      </li>
                      <li>
                        <i className="flaticon-telephone"></i>
                        <a href="tel:+13034386666" className="text-black">
                        303-438-6666
                        
                        </a>
                      </li>
                      <li>
                      <p className="text-black">
        Mon - Sat: 11:00-9:30PM<br />
        Sun: 12:00-9:30PM
      </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6">
                  <div className="widget widget_services">
                    <h5 className="footer-title text-black  ">Our Links</h5>
                    <ul>
                      <li>
                        <Link to="/">
                          <span className="text-black">Home</span>
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="/about-us">
                          <span className="text-black">About Us</span>
                        </Link>
                      </li> */}
                      
                      <li>
                        <Link to="/online-order">
                          <span className="text-black">Online Order</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/menu">
                          <span className="text-black">Menu</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget_services">
                    <h5 className="footer-title text-black">OUR SERVICES</h5>
                    <ul>
                  
                      <li>
                        <Link to="/">
                          <span className="text-black">Top Chefs</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact-us">
                          <span className="text-black">Services</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <span  className="text-black">Pickup In Store</span>
                        </Link>
                      </li>
                     
                    </ul>
                  </div>
                </div>
                  
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-bottom">
            <div className="row">
                <span className="copyright-text">
                  Crafted With{" "}
                  <span
                    className="heart"
                    ref={heartRef}
                    onClick={() => {
                      heartRef.current?.classList.toggle("heart-blast")
                    }}
                  ></span>{" "}
                  by{" "}
                  <Link to="/">
                    Hutao Online
                  </Link>
                </span>
              </div>
              
            </div>
        </div>
      </footer>
    </>
  )
}

export default Footer2
