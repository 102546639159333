import { Link, useNavigate } from "react-router-dom";
import { IMAGES } from "../constent/theme";
import video from '../assets/video/video3.mp4'
import poster2 from '../assets/images/background/sushi4.jpg'
import eventIMg from './event.jpg'

const Home2OurBlog = () => {
  const navigate = useNavigate();
  return (
    <section className="content-inner overflow-hidden">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-12">
            <div className="section-head">
              <h2 className="title wow flipInX">Our Restaurant</h2>
            </div>
            <div className="testimonial-2">
            <div className="testimonial-detail">
              <div className="testimonial-text wow fadeInUp">
                <p>
                Welcome to Ooka Sushi & Asian Cuisine, Broomfield, CO's premier destination for exquisite Japanese and Asian cuisine. While we pride ourselves on our fresh and delectable sushi, our diverse menu also features a wide array of Chinese and Thai dishes, ensuring there's something for everyone. Whether you're craving classic rolls, savory stir-fries, or spicy Thai curries, we have it all. Conveniently order online through our website and take advantage of our reliable delivery service, bringing the best of Asian cuisine right to your doorstep. Experience the finest flavors of the East at Ooka Sushi & Asian Cuisine today!                </p>
              </div>
              <div className="testimonial-info wow fadeInUp"> 
                <h5 className="testimonial-name">We Offer Delivery Within 8 Miles</h5>
                <span className="testimonial-position">Ooka Sushi</span>
                </div>
               
                <div
    onClick={() => navigate('/order')}
    className="btn btn-secondary btn-md shadow-primary m-r30 btn-hover-1"
>
    <span>Order Online</span>
</div>
            </div>
          </div>
            
          </div>
          <div className="col-xl-6 col-lg-12 m-b30 wow fadeInUp">
            {/* <div
              className="dz-card style-3 dz-card-large"
              style={{ backgroundImage: `${eventIMg}` }}
            > */}
              {/* <video autoPlay loop muted playsInline poster={poster2}>
                <source src={video} type="video/mp4"  />
              </video> */}
              <img src={eventIMg} className="eventIMage" alt="menu"/>
              <div className="dz-info">
                <h3 className="dz-title">
                  <Link to="/" className="text-white">
                    Exploring the World of Sushi
                  </Link>
                </h3>
                <div className="dz-meta">
                </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home2OurBlog;
