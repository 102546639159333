import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { ProgressBar, Button, Modal, Col, Row, Card, Container } from 'react-bootstrap';
import { getOnlineStatusThunk } from "../store/restaurant";
import { getFreeItemsThunk } from "../store/freeitems";
import {Context} from "../context/AppContext";
import AddToCartModal from "../elements/AddCartModal";
import CategoryModal from "../elements/CategoryModal";
import AddFreeItemModal from "../elements/AddFreeItemModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPlus, faShoppingBag, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons/faMinusCircle";
import ViewCartModal from "../elements/ViewCartModal";
import Header from "../components/Header";

const OnlineOrder = ({ restaurant }) => {
  const [activeCategory, setActiveCategory] = useState(0);
  const [categoryModalVisible, setCategoryModalVisible] = useState(false);
  const dispatch = useDispatch();
  const categoryListRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const restaurantStatus = useSelector((state) => state.restaurants.onlineStatus);
  const [currentCart, setCurrentCart] = useState([]);
  const freeItems = useSelector((state) => state.freeItems.freeitems);
  const { restaurantId, changedCart, language } = useContext(Context);
  const [addModal, setAddToCartModal] = useState(false);
  const [freeItemModal, setFreeItemModal] = useState(false);
  const [addFreeItemModal, setAddFreeItemModal] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [shake, setShake] = useState(false);
  const [viewCartModalVisible, setViewCartModalVisible] = useState(false);
  const navigate = useNavigate()
  const [currentFreeItem, setCurrentFreeItem] = useState(JSON.parse(localStorage.getItem("freeItem")) || false);

  useEffect(() => { 
    setCurrentFreeItem(JSON.parse(localStorage.getItem("freeItem")) || false);
  },[changedCart])

  const calculateCartItems = () => {
    return currentCart.reduce((total, item) => total + item.quantity, 0);
  };

  
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("cart"));
    if (items) {
      setCurrentCart(items);
    }
  }, [changedCart]);
  
  useEffect(() => {
    const itemCount = calculateCartItems();
    if (itemCount > 0) {
      setShake(true);
      const timer = setTimeout(() => setShake(false), 1000); // Duration of the shake animation
      return () => clearTimeout(timer);
    }
  }, [currentCart]);

  useEffect(() => {
    dispatch(getOnlineStatusThunk(restaurantId));
    dispatch(getFreeItemsThunk(restaurantId));
  }, [dispatch]);

  const calculateCurrentCart = () => {
    let subtotal = currentCart.reduce((total, cartItem) => total + cartItem.Item.price * cartItem.quantity, 0);
    currentCart.forEach((cartItem) => {
      cartItem.options.forEach((option) => {
        subtotal += option.priceModifier * cartItem.quantity;
      });
    });
    return subtotal.toFixed(2);
  };

  const handleOpenAddToCartModal = (item) => {
    if (restaurantStatus?.stop_order) {
      Swal.fire({
        icon: 'error',
        title: 'Restaurant Too Busy',
        text: 'Online order is currently closed!',
      });
      return;
    }

    setSelectedItem(item);  
    setAddToCartModal(true);
  };

  const displayFreeItem = () => {
    for (let i = 0; i < freeItems.length; i++) {
      if (calculateCurrentCart() <= freeItems[i].need_amount) {
        return freeItems[i];
      }
      return freeItems[freeItems.length - 1];
    }
  };

  const getCategoryOffset = (categoryId) => {
    const categoryElement = document.getElementById(`category-${categoryId}`);
    if (categoryElement) {
      const rect = categoryElement.getBoundingClientRect();
      return rect.top + window.scrollY - 200;
    }
    return 0;
  };

  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId);
    const categoryOffset = getCategoryOffset(categoryId + 1);
    window.scrollTo({ top: categoryOffset, behavior: 'smooth' });
    setCategoryModalVisible(false);

    // Scroll the category list to make the clicked category fully visible
    const activeCategoryElement = document.querySelector(`.category-item:nth-child(${categoryId + 1})`);
    if (activeCategoryElement && categoryListRef.current) {
      const elementLeft = activeCategoryElement.offsetLeft;
      const containerWidth = categoryListRef.current.clientWidth;
      const elementWidth = activeCategoryElement.clientWidth;
      
      const centerPosition = elementLeft - (containerWidth / 2) + (elementWidth / 2);
      categoryListRef.current.scrollTo({
        left: centerPosition,
        behavior: "smooth",
      });
    }
  };

  const scrollToActiveCategory = () => {
    const activeCategoryElement = document.querySelector(".category-item.active");
    if (activeCategoryElement && categoryListRef.current) {
      const containerScrollLeft = categoryListRef.current.scrollLeft;
      const containerWidth = categoryListRef.current.clientWidth;
      const elementLeft = activeCategoryElement.offsetLeft;
      const elementWidth = activeCategoryElement.clientWidth;
      
      // Adjust the scroll position to make the active category fully visible
      if (elementLeft < containerScrollLeft) {
        // Scroll left to ensure the active category is fully visible
        categoryListRef.current.scrollTo({
          left: elementLeft - 100,
          behavior: "smooth",
        });
      } else if (elementLeft + elementWidth > containerScrollLeft + containerWidth) {
        // Scroll right to ensure the active category is fully visible
        categoryListRef.current.scrollTo({
          left: elementLeft + elementWidth - containerWidth + 100,
          behavior: "smooth",
        });
      } else {
        // Center the active category if it's not fully visible
        const centerPosition = elementLeft - (containerWidth / 2) + (elementWidth / 2);
        categoryListRef.current.scrollTo({
          left: centerPosition,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const categoryElements = restaurant?.Categories.map((_, id) =>
        document.getElementById(`category-${id + 1}`)
      );

      const scrollPosition = window.scrollY + window.innerHeight / 2;
      let activeCategory = 0;

      categoryElements.forEach((element, index) => {
        if (element) {
          const rect = element.getBoundingClientRect();
          const offsetTop = rect.top + window.scrollY;

          if (scrollPosition >= offsetTop) {
            activeCategory = index;
          }
        }
      });

      setActiveCategory(activeCategory);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [restaurant]);

  useEffect(() => {
    scrollToActiveCategory();
  }, [activeCategory]);


  const handleCheckSameFreeItem = (item) => {
    const cart = JSON.parse(localStorage.getItem(`freeItem`)) || {};
    if(cart?.Item?.name == item.Item.name){ 
      return true;
    }
    return false
  }

  const ifFreeItemALreadyExists = () => { 
    const cart = JSON.parse(localStorage.getItem(`freeItem`)) || {};
    
    if(cart?.Item?.name){ 
      return true;
    }
    return false
  }

  const handleOpenAddFreeItemModal = (item) => {
    if (ifFreeItemALreadyExists()) {
      setFreeItemModal(false);
      Swal.fire({
        icon: 'error',
        title: 'Free Item Already Added',
        text: 'You can only add one free item per order!, Remove from cart first and add!',
      });
      return;
    }
    setSelectedItem(item.Item);
    setAddFreeItemModal(true);
  };

  const toggleCategory = (categoryId) => {
    setExpandedCategories((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId]
    }));
  };

  const handleFastAddToCart = (item, quantity) => { 
    const hasRequiredAttributes = item?.Restaurant_Item_Attributes.some((attribute) => 
      attribute.Item_Attribute.requ > 0
    );

  
    if (hasRequiredAttributes) {
      setSelectedItem(item);
      setAddToCartModal(true);
      return;
    }

    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const itemToAdd = {
      Item: item,
      quantity: quantity,
      special_instructions: '',
      options: [],
      price: item.price,
    };

       // Check if an identical item already exists in the cart
       const existingItemIndex = cart.findIndex(
        cartItem =>
          cartItem.Item.id === itemToAdd.Item.id &&
          cartItem.special_instructions === itemToAdd.special_instructions &&
          JSON.stringify(cartItem.options) === JSON.stringify(itemToAdd.options)
      );
  
      if (existingItemIndex !== -1) {
        // If the item already exists, increase its quantity
        cart[existingItemIndex].quantity += 1;
      } else {
        // Otherwise, add the new item to the cart
        cart.push(itemToAdd);
      }
  
      localStorage.setItem('cart', JSON.stringify(cart));

      setCurrentCart(cart);
      
    
  }

  const checkItemQuantity = (item) => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingItem = cart.find((cartItem) => cartItem.Item.id === item.id);
    return existingItem?.quantity > 0 ? existingItem.quantity : false;
  }


  const handleFastRemoveFromCart = (item) => {
    //remove quanity 1 by 1, then if quantity is 0, remove item from cart
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingItemIndex = cart.findIndex(cartItem => cartItem.Item.id === item.id);
    if (existingItemIndex !== -1) {
      cart[existingItemIndex].quantity -= 1;
      if (cart[existingItemIndex].quantity === 0) {
        cart.splice(existingItemIndex, 1);
      }
    }
    localStorage.setItem('cart', JSON.stringify(cart));
    setCurrentCart(cart);
  }


  return (
    <>
    <Header />
      <div className="page-content bg-white ">
        <section className="content-inner">
          <div className="category-menu-wrapper">
            
          <div className="category-container container d-flex align-items-center">
            
            <i className="fa fa-bars category-icon" onClick={() => setCategoryModalVisible(true)}></i>
            <div className="category-list-wrapper" ref={categoryListRef}>
              <div className="category-list">
                {restaurant?.Categories.map((category, index) => (
                  <span
                    key={category.id}
                    className={`category-item ${activeCategory === index ? 'active' : ''}`}
                    onClick={() => handleCategoryClick(index)}
                  >
                    {category.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
          </div>

          <div className="container" style={{marginBottom:'100px'}}>
            <div className="row">
              {restaurant?.Categories &&
                restaurant.Categories.filter(category => category.id !== 105 && category.id !== 104).map(({ name, Items }, id) => {
                  // Sort items to display those with images first
                  const sortedItems = Items.sort((a, b) => (b.image_url ? 1 : 0) - (a.image_url ? 1 : 0));
                  
                  return (
                    <div key={id} id={`category-${id + 1}`} className="mt-3">
                      <h2>{name}</h2>
                      <div className="row">
                        {sortedItems.map(({ id, name, description, price, image_url, Restaurant_Item_Attributes, chinese_name }) => (
                          <div key={id} className="col-md-6 mb-3"
                            onClick={() => handleOpenAddToCartModal({ id, name, description, price, image_url, Restaurant_Item_Attributes, chinese_name })}
                          >
                            <div className="item-card d-flex flex-row">
                              <div className="item-image-container">
                              {image_url && (
                                <img 
                                  src={image_url} 
                                  alt={name} 
                                  className="item-image" 
                                />
                                )}
                              </div>
                              <div className="item-details flex-grow-1">
                                <h5 className="item-title">{name}</h5>

                                <div className="item-price-container d-flex align-items-center justify-content-between">
                                <p className="item-price">${price}</p>

                                <div className="d-flex align-items-center">
                                {
                                  checkItemQuantity({ id, name, description, price, image_url, Restaurant_Item_Attributes, chinese_name }) && ( 
                                    <>
                                    <FontAwesomeIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleFastRemoveFromCart({ id, name, description, price, image_url, Restaurant_Item_Attributes, chinese_name })
                                    } 
                                    } icon={faMinusCircle} fontSize={26}/>

                                    <span className="mx-2"
                                    style={{fontWeight:'bold'}}
                                    >{checkItemQuantity({ id, name, description, price, image_url, Restaurant_Item_Attributes, chinese_name })}</span>
                                    </>
                                  )
                                }

                                
                                <FontAwesomeIcon 
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleFastAddToCart(
                                  { id, name, description, price, image_url, Restaurant_Item_Attributes, chinese_name },
                                  1
                                )} }icon={faPlusCircle} fontSize={26}/>
                                </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="row fixed-bottom text-center custom-footer bg-white text-black">
            {freeItems.length > 0 && (
                <div className="d-flex align-items-center p-3 border newshadow">
                  <div className="flex-grow-1" style={{ cursor: 'pointer' }} onClick={() => setFreeItemModal(true)}>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-uppercase text-danger">Free</span>
                      <span className="m-0">{calculateCurrentCart()}</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span style={{ fontWeight: 'bold' }}>{displayFreeItem()?.name}</span>
                      <span className="m-0">/{displayFreeItem()?.need_amount}</span>
                    </div>
                    <ProgressBar 
                    label={`${Math.min((calculateCurrentCart() / displayFreeItem()?.need_amount) * 100, 100).toFixed(2)}%`}
                    now={(calculateCurrentCart() / displayFreeItem()?.need_amount) * 100} 
                     />
                  </div>
                  <div className="mx-3" style={{ borderLeft: '1px solid #ccc', height: '100%', marginLeft: '15px', marginRight: '15px' }}></div>
                  <div className="flex-shrink-0" onClick={() => setFreeItemModal(true)}>
                    <p style={{ color: 'red', cursor: 'pointer', margin: 0, whiteSpace: 'nowrap', fontSize: 'small', textAlign: 'center', fontWeight: 'bold' }}>View All<br />Free Item</p>
                  </div>
                </div>
              )}
      {currentCart?.length > 0 && (
        <div className="custom-footer-section px-4 p-1 " onClick={() => navigate('/checkout')}>
          <FontAwesomeIcon icon={faShoppingBag} fontSize={25} className={`text-white ${shake ? 'swing' : ''}`}
 />
          <p  className="btn text-white">
            View Cart
          </p>
          <span className="badge badge-pill custom-badge">{calculateCartItems()}</span>
        </div>
      )}
    </div>
          </div>
        </section>


        <AddToCartModal
          modal={addModal}
          item={selectedItem}
          setModal={setAddToCartModal}
        />
        <CategoryModal show={categoryModalVisible} onClose={() => setCategoryModalVisible(false)}>
          <div className="category-modal-content">
            {restaurant?.Categories.map((category, index) => (
              <div
                key={category.id}
                className={`category-modal-item ${activeCategory === index ? 'active' : ''}`}
                onClick={() => handleCategoryClick(index)}
              >
                {category.name}
              </div>
            ))}
          </div>
        </CategoryModal>

        <Modal show={freeItemModal} onHide={() => setFreeItemModal(false)} centered scrollable size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Free Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <p className="text-center text-danger">
                  You can get a free item when you spend the required amount.
                  <br />
                  *Only one free item per order.
                </p>
              </Col>
            </Row>
            <hr className="mt-0 mb-3" />
            <Container>
              {freeItems.map((item, index) => {
                const isReached = calculateCurrentCart() >= +item?.need_amount;
                const isExpanded = expandedCategories[item.id];

                return (
                  <div key={index} className="mb-4">
                    <Row className="justify-content-center">
                      <Col xs={12} onClick={() => toggleCategory(item.id)} style={{ cursor: 'pointer' }}>
                        <div
                          className="d-flex justify-content-between align-items-center text-center mb-3"
                          style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px' }}
                        >
                          <h4>{item.name} - ({item.ReedemableItems.length} Item) </h4>
                          <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
                        </div>
                      </Col>
                    </Row>
                    {isExpanded && (
                      <div>
                        <Row className="justify-content-center">
                          {item?.ReedemableItems?.map((reedemableItem, reedemableIndex) => (
                            <Col key={reedemableIndex} md={4} sm={6} xs={12} className="mb-4 d-flex align-items-stretch">
                              <Card className="w-100 h-100" style={{ border: '1px solid #ccc' }}>
                                <Card.Body className="d-flex flex-column text-center">
                                    <Card.Img
                                      variant="top"
                                      className="align-self-center"
                                      src={reedemableItem.Item.image_url || 'https://theme-assets.getbento.com/sensei/3914814.sensei/assets/images/catering-item-placeholder-704x520.png'}
                                      style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '50%' }}
                                    />
                                  
                                  <Card.Title>{reedemableItem.Item.name}</Card.Title>
                                  <Card.Text style={{ fontWeight: 'bold', color: isReached ? 'green' : 'red' }}>
                                    ${calculateCurrentCart()} / ${item.need_amount}
                                  </Card.Text>
                                  <ProgressBar
                                    now={(calculateCurrentCart() / item.need_amount) * 100}
                                    label={`${Math.min((calculateCurrentCart() / item.need_amount) * 100, 100).toFixed(2)}%`}
                                  />
                                  <button
                                    variant={handleCheckSameFreeItem(reedemableItem) ? 'secondary' : 'primary'}
                                    className={`black-button mt-2 ${handleCheckSameFreeItem(reedemableItem) || !isReached || ifFreeItemALreadyExists() ? 'disable-button' : 'enable-button'}`}
                                    disabled={!isReached}
                                    onClick={() => handleOpenAddFreeItemModal(reedemableItem)}
                                  >
                                    {handleCheckSameFreeItem(reedemableItem) ? 'In Cart' : 'Add to Cart'}
                                  </button>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                  </div>
                );
              })}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex align-items-center  ">
              {currentFreeItem && (
                <div className="d-flex justify-content-between align-items-center">
                    <span className="me-2">Current:</span>

                    {currentFreeItem?.Item.image_url && (
                    <img
                      src={currentFreeItem?.Item.image_url}
                      alt="free item"
                      style={{ width: "50px", height: "50px", borderRadius: '50%', marginRight: '10px' }}
                    />
                  )}
                    <h5 className="mb-0">{currentFreeItem?.Item.name}</h5>
                  <FontAwesomeIcon icon={faTrashAlt} onClick={() => {
                    localStorage.removeItem("freeItem");
                    setCurrentFreeItem(false);
                  }} style={{ cursor: 'pointer' }} />
                    

                  </div>
              )}

            <button className="black-button" onClick={() => setFreeItemModal(false)}>
              Close
            </button>
            </div>
          </Modal.Footer>
          <AddFreeItemModal item={selectedItem} modal={addFreeItemModal} setModal={setAddFreeItemModal} />
        </Modal>

        <ViewCartModal
          show={viewCartModalVisible}
          handleClose={() => setViewCartModalVisible(false)}
          currentCart={currentCart}
          restaurant={restaurant}
        />

     
      </div>
    </>
  );
};

export default OnlineOrder;
